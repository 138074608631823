import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Component({
  selector: 'app-idepos-page',
  templateUrl: './idepos-page.component.html',
  styleUrls: ['./idepos-page.component.scss'],
})
export class IdeposPageComponent {
  constructor(
    @Inject(PLATFORM_ID) platform: Object,
    private _cookieServiceSSR: SsrCookieService
  ) {
    this.isBrowser = isPlatformBrowser(platform);

    if (this.isBrowser) {
      this.langCode = this._cookieServiceSSR.get('langCode') as string;
    }
  }

  isBrowser?: boolean;
  langCode?: string = 'id_ID';

  openContact() {
    let contact = '';
    let text = '';
    if (this.langCode === 'id_ID') {
      contact = '6288802380775';
      text =
        'Halo! Saya tertarik untuk mengetahui lebih lanjut mengenai produk/layanan Ideku';
    } else {
      contact = '6587747121';
      text =
        "Hello! I'm interested in knowing more about Ideku products/services";
    }

    window.open(`https://wa.me/${contact}?text=${text}`);
  }
}
