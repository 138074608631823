import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppSettings } from '@configs/app.setting';
import { UniversalInterceptor } from '@configs/universal.interceptor';
import { IdekuPageModule } from '@layouts/ideku-page.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { routing } from '@pages/pages.routing';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { CookieModule, CookieService } from 'ngx-cookie';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ViewService } from './shared/data-access/view/view.service';
import { translateBrowserLoaderFactory } from './shared/loaders/translate-browser.loader';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ideku-id' }),
    HttpClientModule,
    BrowserAnimationsModule,
    CookieModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    CookieModule.forRoot(),
    CarouselModule,
    IdekuPageModule,
    RecaptchaModule,
    ToastrModule.forRoot(),
    routing,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise-fade' }),
    AppRoutingModule,
  ],
  providers: [
    ViewService,
    CookieService,
    TranslateService,
    ToastrService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UniversalInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: AppSettings.GOOGLE_RECAPTCHA_PUBLIC_KEY,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
