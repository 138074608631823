import { Component } from '@angular/core';

@Component({
  selector: 'app-header-idepos',
  templateUrl: './header-idepos.component.html',
  styleUrls: ['./header-idepos.component.scss'],
})
export class HeaderIdeposComponent {

}
