import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
  PLATFORM_ID,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import {
  Meta,
  Title,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { environment } from 'src/environments/environment';
import { ViewService } from './shared/data-access/view/view.service';
import { myEnv } from './shared/utils/utils';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'ideku.id';
  isBrowser?: boolean;

  langCode?: string = 'id_ID';
  year!: string;

  constructor(
    private translate: TranslateService,
    private transferState: TransferState,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) platform: Object,
    @Optional() @Inject('LANGUAGE') public language: string,
    @Optional() @Inject('COUNTRY_CODE') public headerCountryCode: string,
    private renderer2: Renderer2,
    private titleService: Title,
    private _metaService: Meta,
    private _cookieServiceSSR: SsrCookieService,
    private _activatedRoute: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private _viewService: ViewService
  ) {
    const LANG_KEY = makeStateKey<string>('langCode');
    const COUNTRY_KEY = makeStateKey<string>('countryCode');

    let langCode: string;
    let countryCode: string;
    if (isPlatformBrowser(platform)) {
      langCode = this.transferState.get(LANG_KEY, 'en_US');
      countryCode = this.transferState.get(COUNTRY_KEY, 'SG');
      this.isBrowser = isPlatformBrowser(platform);
      this._cookieServiceSSR.set('langCode', langCode);
      this._viewService.setLocalStorage('countryCode', countryCode);
    } else {
      if (this._cookieServiceSSR.get('langCode')) {
        this.language = this._cookieServiceSSR.get('langCode');
      }

      this.transferState.set(LANG_KEY, this.language);
      this.transferState.set(COUNTRY_KEY, this.headerCountryCode);
      langCode = this.language;
      countryCode = this.headerCountryCode;
    }

    translate.addLangs(['en_US', 'id_ID']);
    translate.setDefaultLang('en_US');
    translate.use(langCode);

    if (countryCode === 'ID') {
      this.setSeoIdekuID();
      this.setAnalyticsGtag('G-6G0J4JQGCT');
    } else {
      this.setSeoIdekuIO();
      this.setAnalyticsGtag('G-291PNBHZ0H');
    }

    console.log('env: ', myEnv());
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      let preloader = this.document.querySelector('#preloader');
      if (preloader) {
        window?.addEventListener('load', () => {
          preloader?.remove();
        });
      }

      AOS.init(); //AOS - 2
      AOS.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
    }
  }

  ngAfterViewInit(): void {
    this._activatedRoute.queryParams.forEach((params) => {
      if (params['langCode'] === 'en_US' || params['langCode'] === 'id_ID') {
        this.language = params['langCode'];
        this._cookieServiceSSR.set('langCode', this.language);
        this.translate.use(this.language);
      }
    });

    this.setLiveChat();

    this.cdref.detectChanges();
  }

  setSeoIdekuID() {
    this.titleService.setTitle(
      'IDEKU: Innovative POS Solutions to Empower F&B Businesses'
    );
    this._metaService.addTag({
      name: 'google-site-verification',
      content: 'U6asXOjdUp1NhpaH3_T4mf9voBPqHj-nZVoDYpwM_Ws',
    });
    this._metaService.updateTag({
      name: 'description',
      content:
        'Optimise F&B Business Operations with our User-friendly POS and Simplify Orders with QR Ordering System from IDEKU.',
    });
    this._viewService.setDynamicMetaTags(
      'https://ideku.io/',
      'IDEKU: Innovative POS Solutions to Empower F&B Businesses',
      'Optimise F&B Business Operations with our User-friendly POS and Simplify Orders with QR Ordering System from IDEKU.',
      'https://ideku.io/assets/images/logo/logo-banner-ideku.png'
    );
  }

  setSeoIdekuIO() {
    this.titleService.setTitle(
      'IDEKU: Innovative POS Solutions to Empower F&B Businesses'
    );
    this._metaService.addTag({
      name: 'google-site-verification',
      content: 'wiY3NajnkFahar2sQRcz7o0RdmqiEnA8CefGY_KjWrw',
    });
    this._metaService.updateTag({
      name: 'description',
      content:
        'Optimise F&B Business Operations with our User-friendly POS and Simplify Orders with QR Ordering System from IDEKU.',
    });
    this._viewService.setDynamicMetaTags(
      'https://ideku.io/',
      'IDEKU: Innovative POS Solutions to Empower F&B Businesses',
      'Optimise F&B Business Operations with our User-friendly POS and Simplify Orders with QR Ordering System from IDEKU.',
      'https://ideku.io/assets/images/logo/logo-banner-ideku.png'
    );
  }

  setSeoIdekuSG() {
    this.titleService.setTitle(
      'IDEKU : Revolutionising Business Growth and Branding'
    );
    this._metaService.addTag({
      name: 'google-site-verification',
      content: 'QjTSqUWtzWAVidMrBG7UcgAHGrMerYhQxgP_DB0qCj0',
    });
    this._metaService.updateTag({
      name: 'description',
      content:
        'Revolutionising Business Growth and Branding. Unlock Your Full Potential with Our Innovative Platforms : IDEPOS, IDEFOOD, IDEPAGE and IDESHOP.',
    });
    this._viewService.setDynamicMetaTags(
      'https://ideku.io/',
      'IDEKU : Revolutionising Business Growth and Branding',
      'Revolutionising Business Growth and Branding. Unlock Your Full Potential with Our Innovative Platforms : IDEPOS, IDEFOOD, IDEPAGE and IDESHOP.',
      'https://ideku.io/assets/images/logo/logo-banner-ideku.png'
    );
  }

  setAnalyticsGtag(idTracking: string) {
    this.addScript(
      this.renderer2,
      null,
      `https://www.googletagmanager.com/gtag/js?id=${idTracking}`
    );

    let dataText = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        
        gtag('config', '${idTracking}');
    `;
    this.addScript(this.renderer2, dataText, null);
  }

  public addScript(renderer2: Renderer2, dataText: any, dataSrc: any): void {
    let script = renderer2.createElement('script');
    if (dataText) {
      script.text = dataText;
      script.defer = true;
    } else if (dataSrc) {
      script.src = dataSrc;
      script.defer = true;
    }
    renderer2.appendChild(this.document.body, script);
  }

  setLiveChat() {
    let dataText = `
        var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode:"siq292a1d42c7c33bb2a1cb85f6c0e2591158ad06b007517d6516334413dba6f374b801a27b6b25342664d84d3ecdde10f9", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.write("<div id='zsiqwidget'></div>");
    `;
    let script = this.renderer2.createElement('script');
    script.text = dataText;
    script.defer = true;
    this.renderer2.appendChild(this.document.body, script);
  }
}
