import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  LIST_FEATURES,
  LIST_SERVICES,
  NavbarMenuModel,
} from '@layouts/ideku-page.model';
import { TranslateService } from '@ngx-translate/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ViewService } from '../../data-access/view/view.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() hideMenu: boolean = false;
  isBrowser?: boolean;
  isServer?: boolean;
  listFeatures: NavbarMenuModel[] = [];
  listServices: NavbarMenuModel[] = [];

  imgIdeku!: string;
  imgIdePos!: string;
  isCareer: boolean = false;
  isHome: boolean = false;
  width: number = 0;
  langCode: string = 'en_US';
  currentLanguage: string = 'en_US';

  languages = [
    {
      image: 'assets/images/logo/sg-logo.png',
      name: 'SG (EN)',
      value: 'en_US',
      order: 1,
    },
    {
      image: 'assets/images/logo/id-logo.png',
      name: 'ID',
      value: 'id_ID',
      order: 2,
    },
  ];

  @Input() header: any;

  showProductDropdown: boolean = false;
  showLanguageDropdown: boolean = false;

  country: string = ''
  isCollapsed: boolean = true

  constructor(
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platform: Object,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private _cookieServiceSSR: SsrCookieService,
    private _viewService: ViewService
  ) {
    if (isPlatformBrowser(this.platform)) {
      this.setLanguage(
        this._cookieServiceSSR.get('langCode') ?? 'en_US',
        false
      );

      this.country = this._viewService.getLocalStorage('countryCode') ?? ''
    }
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.langCode = this._cookieServiceSSR.get('langCode') as string;
      this.width = $(this.document).width() ?? 0;
      if (this.router.url.includes('career')) {
        if (this.width > 992) this.isCareer = true;
      } else {
        this.isCareer = false;
      }
      if (this.router.url == '/') {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    }
    this.listFeatures = LIST_FEATURES;
    this.listServices = LIST_SERVICES;
  }

  ngAfterViewInit(): void {
  }

  @HostListener('window:scroll', ['$event'])
  initializationStickyNavbarCategory() {
    $(() => {
      $(window).on('scroll', () => {
        if ($(window).scrollTop() ?? 0 > 0) {
          $('.navbar-menu').addClass('bt-ideku');
          $('.navbar-menu').addClass('bg-white');
          if (this.router.url.includes('career')) {
            this.isCareer = false;
          }
        }
        if ($(window).scrollTop() == 0) {
          $('.navbar-menu').removeClass('bt-ideku');
          $('.navbar-menu').removeClass('bg-white');
          if (this.router.url.includes('career')) {
            if (this.width > 992) this.isCareer = true;
          }
        }
      });
    });
  }

  changeLogoMenuService(menuName: string, sourceImage: string): void {
    if (menuName === 'ideku') this.imgIdeku = sourceImage;
    if (menuName === 'idepos') this.imgIdePos = sourceImage;
  }

  getImage(menuName: string) {
    if (menuName == 'ideku')
      return this.imgIdeku ? this.imgIdeku : 'logo-ideku-beta';
    return this.imgIdePos ? this.imgIdePos : 'logo-idepos-beta';
  }

  setLanguage(language: string, isReloading: boolean) {
    this.currentLanguage = language;
    this.translate.use(this.currentLanguage);
    this._cookieServiceSSR.set('langCode', this.currentLanguage);

    if (isReloading) {
      window.location.reload();
    }

    let index = 1;
    for (let lang of this.languages) {
      if (lang.value === language) {
        lang.order = index;
      } else {
        lang.order += index;
      }
    }
  }

  sortBy(prop: string) {
    return this.languages.sort((a: any, b: any) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }
}
