<footer>
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div
        class="col-lg-4 d-lg-block d-flex flex-column align-items-center pb-4"
      >
        <img
          src="assets/images/logo/logo-ideku-white.png"
          class="logo"
          alt="logo ideku"
          fetchpriority="low"
          loading="lazy"
        />

        <p class="text-desc">
          {{ 'IDEKU_DESC_FOOTER' | translate }}
        </p>

        <div class="social-media">
          <a
            href="https://www.instagram.com/ideku.{{
              countryCode === 'ID' ? 'idn' : 'io'
            }}"
            target="_blank"
          >
            <img
              src="assets/images/png/instagram-2.png"
              alt="logo instagram"
              fetchpriority="low"
              loading="lazy"
            />
          </a>
          <a
            href="https://www.tiktok.com/@ideku{{
              countryCode === 'ID' ? 'id' : '.io'
            }}"
            target="_blank"
          >
            <img
              src="assets/images/png/tik-tok-2.png"
              alt="logo tiktok"
              fetchpriority="low"
              loading="lazy"
            />
          </a>
          <a
            href="https://www.facebook.com/ideku.{{
              countryCode === 'ID' ? 'idn' : '.io'
            }}"
            target="_blank"
          >
            <img
              src="assets/images/png/facebook-2.png"
              alt="logo facebook"
              fetchpriority="low"
              loading="lazy"
            />
          </a>
          <a
            href="https://www.youtube.com/@ideku{{
              countryCode === 'ID' ? 'id' : 'io'
            }}"
            target="_blank"
          >
            <img
              src="assets/images/png/youtube-2.png"
              alt="logo youtube"
              fetchpriority="low"
              loading="lazy"
            />
          </a>
          <a href="https://www.linkedin.com/company/ideku-io" target="_blank">
            <img
              src="assets/images/png/logo-linkedin-3.png"
              alt="logo linkedin"
              fetchpriority="low"
              loading="lazy"
            />
          </a>
        </div>
      </div>
      <div class="col-lg-5 mb-4 mb-lg-0">
        <div class="row">
          <div class="col-lg-6 col-12">
            <h3 class="title-menu">{{ "QUICK_LINKS" | translate }}</h3>
            <ul>
              <li>
                <a routerLink="/about-us">
                  {{ "FOOTER_NAV.ABOUT_US" | translate }}
                </a>
              </li>
              <li>
                <a routerLink="/reseller-partner-form"> Reseller/Partner </a>
              </li>
              <li>
                <a routerLink="/blog"> Blog </a>
              </li>
              <li>
                <a routerLink="/promo"> Promo </a>
              </li>
              <li>
                <a routerLink="/contact-us">{{
                  "FOOTER_NAV.CONTACT" | translate
                }}</a>
              </li>
              <li>
                <a routerLink="/career">
                  {{ "FOOTER_NAV.CAREER" | translate }}
                </a>

                <span class="we-are-hiring">We’re hiring</span>
              </li>
            </ul>
          </div>
          <div class="col-lg-6 col-12">
            <h3 class="title-menu">Products</h3>
            <ul>
              <li>
                <a routerLink="/idepos">IDEPOS</a>
              </li>
              <li>
                <a routerLink="/idefood">IDEFOOD</a>
              </li>
              <li>
                <a routerLink="/idepage">IDEPAGE </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-3 subscribe">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="title-menu">{{ "REACH_US" | translate }}</h3>

            <ul>
              <li class="mb-3">
                <img
                  src="assets/images/png/ic-email-icon.png"
                  alt="ic-email-icon"
                  fetchpriority="low"
                  loading="lazy"
                />
                <a href="mailto:info@ideku.io"> info@ideku.io </a>
              </li>
              <li class="mb-3">
                <img
                  src="assets/images/png/ic-phone-icon.png"
                  alt="ic-phone-icon"
                  fetchpriority="low"
                  loading="lazy"
                />
                <a href="tel:+6587747121"> +65 87747121 </a>
              </li>
              <li class="mb-3">
                <img
                  src="assets/images/png/ic-location-icon.png"
                  alt="ic-location-icon"
                  fetchpriority="low"
                  loading="lazy"
                />
                <p>
                  60 Paya Lebar Road #11-47, Paya Lebar Square, Singapore,
                  409051
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row copyright">
      <div
        class="col-lg-6 d-flex justify-content-md-start align-items-center justify-content-sm-center"
      >
        <span>Ideku Technology Solution Pte Ltd &copy; 2022 - {{ year }}</span>
        <span>&copy; 2022 - {{ year }} &nbsp; Ideku</span>
      </div>

      <div
        class="col-lg-6 d-flex justify-content-md-end align-items-center justify-content-sm-center"
      >
        <a href="/terms-and-conditions" class="text-white">{{
          "FOOTER_NAV.TNC" | translate
        }}</a>
        &nbsp; | &nbsp;<a
          href="/privacy-policy"
          class="text-white"
          >{{ "FOOTER_NAV.PRIVACY_POLICY" | translate }}</a
        >
      </div>
    </div>
  </div>

  <img
    src="assets/images/png/idepos-wings.png"
    alt="idepos-wings"
    class="logo idepos-wings"
    fetchpriority="low"
    loading="lazy"
  />
</footer>

<!-- <div class="floating-contact" (click)="openContact()">
  <img src="assets/images/svg/whatsapp.svg" alt="whatsapp" />

  <p *ngIf="langCode === 'id_ID'">
    <ngx-typed-js
      [strings]="['Mau Kami Bantu? </br> Hubungi Tim Ideku']"
      contentType="html"
      [loop]="true"
      [showCursor]="false"
      [backDelay]="10000"
    >
      <p class="typing"></p>
    </ngx-typed-js>
  </p>

  <p *ngIf="langCode === 'en_US'">
    <ngx-typed-js
      [strings]="['Any Enquiries and Need Help? </br> Contact Ideku Team']"
      contentType="html"
      [loop]="true"
      [showCursor]="false"
      [backDelay]="10000"
    >
      <p class="typing"></p>
    </ngx-typed-js>
  </p>
</div> -->
