import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ViewService {
  constructor(private _metaService: Meta,
    @Inject(PLATFORM_ID) private platformId: string
    ) {
      this.isPlatformBrowser = isPlatformBrowser(this.platformId)
    }

    isPlatformBrowser: boolean = false

  /**
   *
   * @author Abi A.
   * we have a method for saving data in web browser
   * SESSION STORAGE
   * LOCAL STORAGE
   * COOKIES STORAGE
   *
   */

  setDynamicMetaTags(
    url: string,
    title: string,
    description: string,
    imageUrl: string
  ) {
    // Open Graph (OG) meta tags
    this._metaService.updateTag({ property: 'og:url', content: url });
    this._metaService.updateTag({ property: 'og:title', content: title });
    this._metaService.updateTag({
      property: 'og:description',
      content: description,
    });
    this._metaService.updateTag({ property: 'og:image', content: imageUrl });

    // Twitter meta tags
    this._metaService.updateTag({ property: 'og:url', content: url });
    this._metaService.updateTag({ property: 'twitter:title', content: title });
    this._metaService.updateTag({
      property: 'twitter:description',
      content: description,
    });
    this._metaService.updateTag({
      property: 'twitter:image',
      content: imageUrl,
    });
  }

  /**
   *
   * @sessionStorage
   * USING SESSION STORAGE
   *
   */

  /**
   *
   * @localStorage
   * USING LOCAL STORAGE
   *
   */
  setLocalStorage(key: string, value: string) {
    if (this.isPlatformBrowser) localStorage.setItem(key, value);
  }

  getLocalStorage(key: string) {
    if (!this.isPlatformBrowser) return
    return localStorage.getItem(key);
  }

  /**
   *
   * @cookieService
   * USING COOKIES STORAGE
   *
   */

  goToPortal() {
    let route = '';
    if (environment.local) {
      route = `https://stg-portal.ideshop.io/fnb/dashboard`;
    } else if (environment.development) {
      route = `https://stg-portal.ideshop.io/fnb/dashboard`;
    } else if (environment.uat) {
      route = `https://uat-portal.ideshop.io/fnb/dashboard`;
    } else if (environment.production) {
      route = `https://portal.ideshop.io/fnb/dashboard`;
    }

    window.location.href = route;
  }
}
