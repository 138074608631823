import { environment } from 'src/environments/environment';

export const detectswipe = function (
  id: string,
  callback: (direction: 'left' | 'right') => void
) {
  let xStart: number;
  let xEnd: number;

  function handleChanges() {
    if (!xStart || !xEnd) return;
    if (Math.abs(xEnd - xStart) < 100) return;

    if (xEnd < xStart) {
      callback('left');
    }
    if (xEnd > xStart) {
      callback('right');
    }
  }

  let html = document.getElementById(id);
  if (html && html.addEventListener) {
    // for mobile
    html.addEventListener('touchstart', function (event) {
      event.preventDefault();
      xStart = event.changedTouches[0].screenX;
    });

    html.addEventListener('touchend', function (event) {
      event.preventDefault();
      xEnd = event.changedTouches[0].screenX;
      handleChanges();
    });

    // for desktop

    html.addEventListener('mousedown', function (event) {
      event.preventDefault();
      xStart = event.clientX;
    });

    html.addEventListener('mouseup', function (event) {
      event.preventDefault();
      xEnd = event.clientX;
      handleChanges();
    });
  }
};

export function myEnv() {
  let result = '';
  if (environment.development) {
    result = 'development';
  } else if (environment.uat) {
    result = 'uat';
  } else if (environment.production) {
    result = 'production';
  } else {
    result = 'local';
  }

  return result;
}
