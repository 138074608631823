<nav
  class="navbar navbar-expand-lg navbar-light fixed-top navbar-menu"
  [ngStyle]="{
    background: header?.bg
  }"
>
  <div class="container">
    <a class="navbar-brand" href="/">
      <img
        src="assets/images/png/logo-ideku-black.png"
        width="100"
        alt="ideku"
        fetchpriority="low"
        loading="lazy"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse"
      [class.show]="!isCollapsed"
    >
      <ul class="navbar-nav ms-auto align-items-center" *ngIf="!hideMenu">
        <li class="nav-item page dropdown">
          <a
            class="nav-link"
            href="javascript:void(0)"
            (click)="showProductDropdown = !showProductDropdown"
          >
            {{ "NAV_MENU.PRODUCT" | translate }}
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5238_1931)">
                <path
                  d="M10.5492 4.66059L6.34922 8.2713C6.29922 8.31416 6.24505 8.34445 6.18672 8.36216C6.12839 8.38016 6.06589 8.38916 5.99922 8.38916C5.93255 8.38916 5.87005 8.38016 5.81172 8.36216C5.75339 8.34445 5.69922 8.31416 5.64922 8.2713L1.43672 4.66059C1.32005 4.56059 1.26172 4.43559 1.26172 4.28559C1.26172 4.13559 1.32422 4.00702 1.44922 3.89988C1.57422 3.79273 1.72005 3.73916 1.88672 3.73916C2.05339 3.73916 2.19922 3.79273 2.32422 3.89988L5.99922 7.04987L9.67422 3.89987C9.79089 3.79987 9.93455 3.74987 10.1052 3.74987C10.2762 3.74987 10.4242 3.80345 10.5492 3.91059C10.6742 4.01773 10.7367 4.14273 10.7367 4.28559C10.7367 4.42845 10.6742 4.55345 10.5492 4.66059Z"
                  fill="#404040"
                />
              </g>
              <defs>
                <clipPath id="clip0_5238_1931">
                  <rect
                    width="10.2857"
                    height="12"
                    fill="white"
                    transform="translate(0 11.1428) rotate(-90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
          <div class="dropdown-content" *ngIf="showProductDropdown">
            <a routerLink="/idepos">IDEPOS</a>
            <a routerLink="/idefood">IDEFOOD</a>
            <a routerLink="/idepage">IDEPAGE</a>
          </div>
        </li>

        <li class="nav-item page">
          <a
            class="nav-link"
            routerLink="/about-us"
            routerLinkActive="active"
            >{{ "NAV_MENU.ABOUT_US" | translate }}</a
          >
        </li>

        <li class="nav-item page" *ngIf="country === 'SG'">
          <a class="nav-link" routerLink="/rental" routerLinkActive="active">
            Rental
          </a>
        </li>

        <li class="nav-item page" *ngIf="country === 'SG'">
          <a class="nav-link" routerLink="/promo" routerLinkActive="active">
            Promo
          </a>
        </li>

        <li class="nav-item page" *ngIf="langCode === 'id_ID'">
          <a
            class="nav-link"
            [class.text-white]="isCareer"
            href="https://blog.ideku.id"
            >{{ "NAV_MENU.BLOG" | translate }}</a
          >
        </li>
        <li class="nav-item page">
          <a
            class="nav-link"
            [class.text-white]="isCareer"
            routerLink="/career"
            routerLinkActive="active"
            >{{ "NAV_MENU.CAREER" | translate }}</a
          >
        </li>

        <li class="nav-item page dropdown dropdown-language">
          <a
            class="nav-link"
            href="javascript:void(0)"
            (click)="showLanguageDropdown = !showLanguageDropdown"
          >
            <ng-container *ngIf="currentLanguage === 'id_ID'">
              <img
                src="assets/images/logo/id-logo.png"
                alt="logo Indonesia"
                fetchpriority="low"
                loading="lazy"
              />
              ID
            </ng-container>

            <ng-container *ngIf="currentLanguage === 'en_US'">
              <img
                src="assets/images/logo/sg-logo.png"
                alt="logo Singapore"
                fetchpriority="low"
                loading="lazy"
              />
              SG (EN)
            </ng-container>
          </a>
          <div class="dropdown-content" *ngIf="showLanguageDropdown">
            <a
              href="javascript:void(0)"
              *ngFor="let lang of sortBy('order')"
              (click)="setLanguage(lang.value, true)"
            >
              <img
                [src]="lang.image"
                [alt]="'language ' + lang.name"
                fetchpriority="low"
                loading="lazy"
              />
              <p>{{ lang.name }}</p>
            </a>
          </div>
        </li>

        <li class="nav-item page">
          <button class="btn-gradient-danger" routerLink="/contact-us">
            {{ "NAV_MENU.CONTACT_US" | translate }}
          </button>
        </li>
      </ul>

      <ul class="navbar-nav ms-auto align-items-center" *ngIf="hideMenu">
        <li class="nav-item page">
          <button class="btn-gradient-danger" routerLink="/contact-us">
            {{ "NAV_MENU.CONTACT_US" | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
