<div class="wrapper">
  <app-header-idepos></app-header-idepos>
  <ng-content></ng-content>
</div>

<div class="floating-contact" (click)="openContact()">
  <img
    src="assets/images/svg/whatsapp.svg"
    alt="whatsapp"
    fetchpriority="low"
    loading="lazy"
  />

  <p *ngIf="langCode === 'id_ID'">
    <ngx-typed-js
      [strings]="['Mau Kami Bantu? </br> Hubungi Tim Ideku']"
      contentType="html"
      [loop]="true"
      [showCursor]="false"
      [backDelay]="10000"
    >
      <p class="typing"></p>
    </ngx-typed-js>
  </p>

  <p *ngIf="langCode === 'en_US'">
    <ngx-typed-js
      [strings]="['Any Enquiries and Need Help? </br> Contact Ideku Team']"
      contentType="html"
      [loop]="true"
      [showCursor]="false"
      [backDelay]="10000"
    >
      <p class="typing"></p>
    </ngx-typed-js>
  </p>
</div>
