<nav
  class="site-header navbar navbar-expand-lg navbar-light fixed-top navbar-menu"
>
  <div class="container">
    <a class="navbar-brand" href="/">
      <img
        src="assets/images/png/logo-white-idepos.png"
        width="100"
        alt="ideku"
        fetchpriority="low"
        loading="lazy"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto align-items-center">
        <li class="nav-item page">
          <a class="nav-link" href="/">{{ "NAV_MENU.HOME" | translate }}</a>
        </li>
        <li class="nav-item page dropdown">
          <a class="nav-link" href="javascript:void(0)"
            >{{ "NAV_MENU.FEATURES" | translate }}
          </a>
          <div class="dropdown-content">
            <a routerLink="/idepos">IDEPOS</a>
            <a routerLink="/idepage">IDEPAGE</a>
            <a routerLink="/ideshop">IDESHOP</a>
          </div>
        </li>
        <!-- <li class="nav-item page dropdown">
          <a class="nav-link" href="javascript:void(0)"
            >{{ "NAV_MENU.PRICING" | translate }}
          </a>
          <div class="dropdown-content">
            <a href="/idepage/pricing">IDEPAGE</a>
          </div>
        </li> -->
        <!-- <li class="nav-item page">
          <a class="nav-link" href="/idepos/faq">FAQ</a>
        </li> -->
        <li class="nav-item nav-btn">
          <a
            class="nav-link"
            href="mailto:sales@ideku.io,info@ideku.io?subject=Hello team, I want to ask for idepos"
          >
            <button class="btn-ideku-outline">
              {{ "IDEPOS.CONTACT_US" | translate }}
            </button>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
