import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'career',
    loadChildren: () =>
      import('./careers/careers.module').then((m) => m.CareersModule),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./contact-us/contact-us.module').then((m) => m.ContactUsModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('./terms-conditions/terms-conditions.module').then(
        (m) => m.TermsConditionsModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'idepage',
    loadChildren: () =>
      import('./idepage/idepage.module').then((m) => m.IdepageModule),
  },
  {
    path: 'ideshop',
    loadChildren: () =>
      import('./ideshop/ideshop.module').then((m) => m.IdeshopModule),
  },
  {
    path: 'idepos',
    loadChildren: () =>
      import('./idepos/idepos.module').then((m) => m.IdeposModule),
  },
  {
    path: 'purchase-summary',
    loadChildren: () =>
      import('./purchase-summary/purchase-summary.module').then(
        (m) => m.PurchaseSummaryModule
      ),
  },
  {
    path: 'FAQ',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'idefood',
    loadChildren: () =>
      import('./idefood/idefood.module').then((m) => m.IDEFOODModule),
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'livechat',
    loadChildren: () =>
      import('./livechat/livechat.module').then((m) => m.LivechatModule),
  },
  {
    path: 'tickets',
    loadChildren: () =>
      import('./tickets/tickets.module').then((m) => m.TicketsModule),
  },
  {
    path: 'schedule-demo',
    loadChildren: () =>
      import('./schedule-demo/schedule-demo.module').then(
        (m) => m.ScheduleDemoModule
      ),
  },
  {
    path: 'promo',
    loadChildren: () =>
      import('./promo/promo.module').then((m) => m.PromoModule),
  },
  {
    path: 'rental',
    loadChildren: () => import('./rent/rent.module').then((m) => m.RentModule),
  },
  {
    path: 'pos-system-solution-singapore',
    loadChildren: () => import('./ads/ads.module').then((m) => m.AdsModule),
  },
  {
    path: 'reseller-partner-form',
    loadChildren: () =>
      import('./reseller-partner/reseller-partner.module').then(
        (m) => m.ResellerPartnerModule
      ),
  },
  {
    path: 'grab',
    loadChildren: () =>
      import('./grab/grab.module').then(
        (m) => m.GrabModule
      ),
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(routes);
