import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ViewService } from '../../data-access/view/view.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isBrowser?: boolean;
  langCode?: string = 'id_ID';
  year!: string;
  countryCode!: string;

  constructor(
    @Inject(PLATFORM_ID) platform: Object,
    private _cookieServiceSSR: SsrCookieService,
    private _viewService: ViewService
  ) {
    this.isBrowser = isPlatformBrowser(platform);

    this.langCode = this._cookieServiceSSR.get('langCode') as string;
    this.countryCode = this._viewService.getLocalStorage('countryCode') as string;
  }

  ngOnInit() {
    this.year = new Date().getFullYear().toString();
  }

  openContact() {
    let contact = '';
    let text = '';
    if (this.langCode === 'id_ID') {
      contact = '6288802380775';
      text =
        'Halo! Saya tertarik untuk mengetahui lebih lanjut mengenai produk/layanan Ideku';
    } else {
      contact = '+6586917299';
      text =
        "Hello! I'm interested in knowing more about Ideku products/services";
    }

    window.open(`https://wa.me/${contact}?text=${text}`);
  }
}
